import React from 'react';
import highlight from '../../../assets/images/home/highlight-stories.svg';
import preview1 from '../../../assets/images/home/projects-preview-1.png';
import preview10 from '../../../assets/images/home/projects-preview-10.png';
import preview10_2x from '../../../assets/images/home/projects-preview-10@2x.png';
import preview1_2x from '../../../assets/images/home/projects-preview-1@2x.png';
import preview2 from '../../../assets/images/home/projects-preview-2.png';
import preview2_2x from '../../../assets/images/home/projects-preview-2@2x.png';
import preview3 from '../../../assets/images/home/projects-preview-3.png';
import preview3_2x from '../../../assets/images/home/projects-preview-3@2x.png';
import preview4 from '../../../assets/images/home/projects-preview-4.png';
import preview4_2x from '../../../assets/images/home/projects-preview-4@2x.png';
import preview5 from '../../../assets/images/home/projects-preview-5.png';
import preview5_2x from '../../../assets/images/home/projects-preview-5@2x.png';
import preview6 from '../../../assets/images/home/projects-preview-6.png';
import preview6_2x from '../../../assets/images/home/projects-preview-6@2x.png';
import preview7 from '../../../assets/images/home/projects-preview-7.png';
import preview7_2x from '../../../assets/images/home/projects-preview-7@2x.png';
import preview8 from '../../../assets/images/home/projects-preview-8.png';
import preview8_2x from '../../../assets/images/home/projects-preview-8@2x.png';
import preview9 from '../../../assets/images/home/projects-preview-9.png';
import preview9_2x from '../../../assets/images/home/projects-preview-9@2x.png';
import CtaButton from '../../../components/CtaButton/CtaButton';
import Highlight from '../../../components/Highlight/Highlight';
import Image from '../../../components/Image/Image';
import { Urls } from '../../../constants/Urls.constants';
import styles from './HomeProjects.module.scss';

const HomeProjects: React.FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h2 className={styles.responsive}>Designing for Impact</h2>
        <div className={styles.previews}>
          <Image src={preview1} srcSet={`${preview1} 1x, ${preview1_2x}2x`} />
          <Image src={preview2} srcSet={`${preview2} 1x, ${preview2_2x}2x`} />
          <Image src={preview3} srcSet={`${preview3} 1x, ${preview3_2x}2x`} />
          <Image src={preview4} srcSet={`${preview4} 1x, ${preview4_2x}2x`} />
          <Image src={preview5} srcSet={`${preview5} 1x, ${preview5_2x}2x`} />
          <Image src={preview6} srcSet={`${preview6} 1x, ${preview6_2x}2x`} />
          <Image src={preview7} srcSet={`${preview7} 1x, ${preview7_2x}2x`} />
          <Image src={preview8} srcSet={`${preview8} 1x, ${preview8_2x}2x`} />
          <Image src={preview9} srcSet={`${preview9} 1x, ${preview9_2x}2x`} />
          <Image
            src={preview10}
            srcSet={`${preview10} 1x, ${preview10_2x}2x`}
          />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>Designing for Impact</h2>
          <span className={styles.text}>
            We have many{' '}
            <Highlight
              url={highlight}
              width={150}
              height={36}
              top={12}
              left={-16}>
              stories
            </Highlight>{' '}
            on how our design shapes impactful products and experiences.
          </span>
          <CtaButton
            className={styles.cta}
            cta="Discover our Projects"
            target={Urls.projects}
            fallback="Browse our projects from your computer."
          />
        </div>
      </div>
    </div>
  );
};

export default HomeProjects;

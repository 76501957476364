import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import StateContext from '../context/StateContext';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './Root.module.scss';

interface RootProps {
  route: string;
}

const Root: React.FC<RootProps> = (props: RootProps): JSX.Element => {
  const state = useContext(StateContext);

  return (
    <>
      <Header route={props.route} se={state.layout?.se}></Header>
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer
        yammer={state.layout?.yammer}
        instagram={state.layout?.instagram}
        copyright={state.layout?.copyright}
        lastUpdate={state.layout?.lastUpdate}></Footer>
    </>
  );
};

export default Root;

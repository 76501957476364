import React, { KeyboardEvent } from 'react';
import Ripple from '../Ripple/Ripple';
import styles from './Burger.module.scss';

interface BurgerProps {
  className?: string;
  close?: boolean;
  onToggle?: () => void;
}

const Burger: React.FC<BurgerProps> = (props: BurgerProps): JSX.Element => {
  function toggleBurger() {
    props.onToggle && props.onToggle();
  }

  return (
    <button
      className={`${styles.button} ${props.className} ${
        props.close && styles.close
      }`}
      onClick={toggleBurger}
      onKeyDown={(e: KeyboardEvent) => e.code === 'Enter' && toggleBurger}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
      <Ripple />
    </button>
  );
};

export default Burger;

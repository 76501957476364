import { RefObject, useEffect, useState } from 'react';
import usePosition from './usePosition';
import useWindowSize from './useWindowSize';

function useScrollRatio(ref: RefObject<HTMLElement>): number {
  const [ratio, setRatio] = useState<number>(0);
  const position = usePosition(ref);
  const windowSize = useWindowSize();

  useEffect(() => {
    let scrollRatio = 0;
    if (position.y !== undefined) {
      if (position.y <= windowSize.height) {
        if (position.y <= windowSize.height && position.y > 0) {
          scrollRatio = 1 - position.y / windowSize.height;
        } else {
          scrollRatio = 1;
        }
      }
    }

    if (scrollRatio !== ratio) {
      setRatio(scrollRatio);
    }
  }, [position.y, ratio, windowSize.height]);

  return ratio;
}

export default useScrollRatio;

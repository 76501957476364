import { useEffect } from 'react';

function useMeta(name: string, value: string, type = 'name'): void {
  useEffect(() => {
    // Update existing header tag or create a new one
    let el: Element | null = document.querySelector(`meta[${type}='${name}']`);
    const previous: string | null | undefined = el?.getAttribute('content');
    if (!el) {
      el = document.createElement('meta');
      el.setAttribute(type, name);
      document.head.append(el);
    }
    el.setAttribute('content', value);

    // Cleanup reset attribute (or deletes it)
    return () => {
      if (previous) {
        el?.setAttribute('content', previous);
      } else {
        el?.parentNode?.removeChild(el);
      }
    };
  }, [name, value, type]);
}

export default useMeta;

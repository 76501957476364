import { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { Urls } from '../../constants/Urls.constants';

const order: RegExp[] = [
  new RegExp(`^${Urls.home}$`),
  new RegExp(`^${Urls.process}$`),
  new RegExp(`^${Urls.projects}$`),
  new RegExp(`^${Urls.projects}/(\\d+)$`), // to process
  new RegExp(`^${Urls.guidelines}$`),
  new RegExp(`^${Urls.contact}$`),
];

function getWeight(location: string): number {
  let weight = order.length + 1;
  order.forEach((regexp: RegExp, index: number) => {
    const matches: RegExpExecArray | null = regexp.exec(location);
    if (matches) {
      weight = index;
      if (matches.length > 1) {
        weight += parseInt(matches[1]) / 1000;
      }
    }
  });

  return weight;
}

function useViewTransition(timing: number): {
  display: Location;
  direction: number;
  isLeaving: boolean;
} {
  const location: Location = useLocation();
  const [display, setDisplay] = useState<Location>(location);

  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [direction, setDirection] = useState<number>(0);

  useEffect(() => {
    if (location.pathname !== display.pathname) {
      setIsLeaving(true);
      setDirection(getWeight(display.pathname) - getWeight(location.pathname));

      const timeout: NodeJS.Timeout = setTimeout(() => {
        window.scrollTo(0, 0);
        setIsLeaving(false);
        setDisplay(location);
      }, timing);

      // Cleanup kills timeout
      return () => clearTimeout(timeout);
    }
  }, [location, display, timing]);

  return { display, direction, isLeaving };
}

export default useViewTransition;

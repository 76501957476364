import { useEffect, useState } from 'react';
import bulb from './../../assets/images/header-bulb.gif';
import bulb_2x from './../../assets/images/header-bulb@2x.gif';
import flask from './../../assets/images/header-flask.gif';
import flask_2x from './../../assets/images/header-flask@2x.gif';
import thunder from './../../assets/images/header-thunder.gif';
import thunder_2x from './../../assets/images/header-thunder@2x.gif';

interface SrcSet {
  x1: string;
  x2: string;
}

function getAnimation(exclude?: SrcSet): SrcSet {
  const animations: SrcSet[] = [
    { x1: bulb, x2: bulb_2x },
    { x1: flask, x2: flask_2x },
    { x1: thunder, x2: thunder_2x },
  ];
  if (exclude) {
    animations.splice(
      animations.findIndex((item: SrcSet) => exclude.x1 === item.x1),
      1
    );
  }

  return animations.at(Math.floor(Math.random() * animations.length)) as SrcSet;
}

function useHeaderAnim(
  timing: number,
  route: string
): {
  animation: SrcSet;
  isLeaving: boolean;
} {
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [animation, setAnimation] = useState<SrcSet>(() => getAnimation());
  const [lastRoute, setLastRoute] = useState<string>(route);

  useEffect(() => {
    if (route !== lastRoute) {
      setIsLeaving(true);

      const timeout: NodeJS.Timeout = setTimeout(() => {
        setIsLeaving(false);
        setLastRoute(route);
        setAnimation(getAnimation(animation));
      }, timing);

      // Cleanup kills timeout
      return () => clearTimeout(timeout);
    }
  }, [route, animation, lastRoute, timing]);

  return { animation, isLeaving };
}

export default useHeaderAnim;

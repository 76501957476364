import { useEffect } from 'react';

function useTitle(title: string): void {
  useEffect(() => {
    const previous: string = document.title;
    document.title = title;

    // Cleanup reset title to the previous one
    return () => {
      document.title = previous;
    };
  }, [title]);
}

export default useTitle;

import React from 'react';
import useMeta from '../../hooks/seo/useMeta';
import useTitle from '../../hooks/seo/useTitle';
import HomeGuidelines from './HomeGuidelines/HomeGuidelines';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeProcess from './HomeProcess/HomeProcess';
import HomeProjects from './HomeProjects/HomeProjects';

const Home: React.FC = (): JSX.Element => {
  useTitle('SE | Design & Ergonomics');
  useMeta('description', 'Schneider Design Lab team website');

  return (
    <>
      <HomeHeader />
      <HomeGuidelines />
      <HomeProjects />
      <HomeProcess />
    </>
  );
};

export default Home;

import React from 'react';
import Lottie from 'react-lottie-player';
import highlight from '../../../assets/images/home/highlight-consistent.svg';
import CtaButton from '../../../components/CtaButton/CtaButton';
import Highlight from '../../../components/Highlight/Highlight';
import { Urls } from '../../../constants/Urls.constants';
import useFetch from '../../../hooks/useFetch';
import styles from './HomeGuidelines.module.scss';

const HomeGuidelines: React.FC = (): JSX.Element => {
  const [morphing] = useFetch<object>(
    process.env.PUBLIC_URL + '/animations/morphing.json'
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h2 className={styles.responsive}>
          One brand
          <br />
          One system
          <br />
          One design
        </h2>
        <Lottie
          className={styles.animation}
          loop
          play
          animationData={morphing}
        />
        <div className={styles.content}>
          <h2 className={styles.title}>
            One brand
            <br />
            One system
            <br />
            One design
          </h2>
          <span className={styles.text}>
            We guarantee a{' '}
            <Highlight
              url={highlight}
              width={202}
              height={55}
              left={-12}
              top={3}>
              consistent
            </Highlight>{' '}
            brand experience by offering guidance to project teams and
            designers.
          </span>
          <CtaButton
            cta="Browse our Design Guidelines"
            target={Urls.guidelines}
            fallback="Browse our guidelines from your computer."
          />
        </div>
      </div>
    </div>
  );
};

export default HomeGuidelines;

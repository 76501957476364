export const Segments = {
  home: '',
  projects: 'projects',
  projectDetails: ':id',
  process: 'process',
  guidelines: 'guidelines',
  contact: 'contact',
} as const;

export const Urls = {
  home: '/' + [Segments.home].join('/'),
  projects: '/' + [Segments.projects].join('/'),
  projectDetails: '/' + [Segments.projects, Segments.projectDetails].join('/'),
  process: '/' + [Segments.process].join('/'),
  guidelines: '/' + [Segments.guidelines].join('/'),
  contact: '/' + [Segments.contact].join('/'),
} as const;

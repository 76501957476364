import { RefObject, useEffect, useState } from 'react';

interface Position {
  x?: number;
  y?: number;
}

function usePosition(ref: RefObject<HTMLElement>): Position {
  const [position, setPosition] = useState<Position>({});

  useEffect(() => {
    let frameReq: number;
    const process = () => {
      if (
        position.x !== ref?.current?.getBoundingClientRect().x ||
        position.y !== ref?.current?.getBoundingClientRect().y
      ) {
        setPosition({
          x: ref?.current?.getBoundingClientRect().x,
          y: ref?.current?.getBoundingClientRect().y,
        });
      }

      frameReq = requestAnimationFrame(() => {
        process();
      });
    };

    process();

    return () => cancelAnimationFrame(frameReq);
  });

  return position;
}

export default usePosition;

import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import highlight from '../../../assets/images/home/highlight-preferable-ones.svg';
import CtaButton from '../../../components/CtaButton/CtaButton';
import Highlight from '../../../components/Highlight/Highlight';
import { Urls } from '../../../constants/Urls.constants';
import useMaxPageScroll from '../../../hooks/dom/useMaxPageScroll';
import useScrollRatio from '../../../hooks/dom/useScrollRatio';
import useFetch from '../../../hooks/useFetch';
import styles from './HomeProcess.module.scss';

const HomeProcess: React.FC = (): JSX.Element => {
  const [designJourney] = useFetch<object>(
    process.env.PUBLIC_URL + '/animations/design-journey.json'
  );

  const animationRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const lottieRef = useRef<any>(null);
  const scrollRatio = useScrollRatio(animationRef);
  const maxPageScroll = useMaxPageScroll(10);
  const [currentFrame, setCurrentFrame] = useState<number | undefined>(
    undefined
  );
  const [targetFrame, setTargetFrame] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (currentFrame !== targetFrame) {
      const frameReq: number = requestAnimationFrame(() => {
        if (targetFrame !== undefined) {
          setCurrentFrame(
            (currentFrame ?? 0) + (targetFrame > (currentFrame ?? 0) ? 1 : -1)
          );
        } else {
          setCurrentFrame(undefined);
        }
      });

      return () => cancelAnimationFrame(frameReq);
    }
  }, [currentFrame, targetFrame]);

  useEffect(() => {
    if (!maxPageScroll) {
      setTargetFrame(Math.round(scrollRatio * lottieRef.current?.totalFrames));
    } else {
      setTargetFrame(lottieRef.current?.totalFrames);
    }
  }, [maxPageScroll, targetFrame, scrollRatio]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h2>Design is a journey</h2>
        <div ref={animationRef}>
          <Lottie
            ref={lottieRef}
            className={styles.animation}
            loop={false}
            goTo={currentFrame}
            useSubframes={true}
            animationData={designJourney}
          />
        </div>
        <span className={styles.text}>
          Our process is transforming existing{' '}
          <span className={styles.arrow}>situations</span> into{' '}
          <Highlight url={highlight} width={267} height={41} left={-16} top={1}>
            preferable ones.
          </Highlight>
        </span>
        <CtaButton
          className={styles.cta}
          cta=" See how to work with us"
          target={Urls.process}
          fallback="See how to work with us from your computer."
        />
      </div>
    </div>
  );
};

export default HomeProcess;

import React, { useEffect, useRef, useState } from 'react';
import useElementVisible from '../../hooks/dom/useElementVisible';
import Image from '../Image/Image';
import styles from './Highlight.module.scss';

interface HighlightProps {
  children: React.ReactNode;
  url: string;
  url2x?: string;
  width: number;
  height: number;
  top?: number;
  left?: number;
  overrideVisibilityCheck?: boolean;
  overrideAnimationDelay?: number;
}

const Highlight: React.FC<HighlightProps> = (
  props: HighlightProps
): JSX.Element => {
  const ref = useRef<HTMLSpanElement>(null);
  const visible = useElementVisible(ref, 0.25);
  const [classes, setClasses] = useState<string[]>([
    styles.wrapper,
    styles.hidden,
  ]);

  useEffect(() => {
    if (props.overrideVisibilityCheck) {
      const timeout = setTimeout(() => {
        setClasses([styles.wrapper]);
      }, (props.overrideAnimationDelay ?? 0.5) * 1000);

      return () => clearTimeout(timeout);
    } else if (visible) {
      setClasses([styles.wrapper]);
    }
  }, [visible, props.overrideVisibilityCheck, props.overrideAnimationDelay]);

  let srcSet = `${props.url} 1x`;
  if (props.url2x) {
    srcSet += `, ${props.url2x} 2x`;
  }

  return (
    <span className={classes.join(' ')} ref={ref}>
      {props.children}
      <div
        className={styles.image}
        style={{
          width: props.width + 'px',
          height: props.height + 'px',
          left: (props.left ?? 0) + 'px',
          top: (props.top ?? 0) + 'px',
          transitionDelay: (props.overrideAnimationDelay ?? 0.5) + 's',
        }}>
        <Image
          style={{
            width: props.width + 'px',
            height: props.height + 'px',
          }}
          src={props.url}
          srcSet={srcSet}
        />
      </div>
    </span>
  );
};

export default Highlight;

import React, { useEffect, useState } from 'react';
import { Breakpoint } from '../../enums/Breakpoint.enum';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useTitle from '../../hooks/seo/useTitle';
import useFirstVisit from '../../hooks/utils/useFirstVisit';
import styles from './ContentPage.module.scss';

interface ContentPageProps {
  title: string;
  baseline?: React.ReactNode;
  children?: React.ReactNode;
  animation?: React.ReactNode;
}

function getClasses(firstVisit: boolean, breakpoint: Breakpoint): string[] {
  return [
    styles.wrapper,
    ...(firstVisit && breakpoint > Breakpoint.Small ? [styles.animate] : []),
  ];
}

const ContentPage: React.FC<ContentPageProps> = (
  props: ContentPageProps
): JSX.Element => {
  useTitle(`SE | ${props.title}`);
  const firstVisit: boolean = useFirstVisit();
  const breakpoint: Breakpoint = useBreakpoint();
  const [classes, setClasses] = useState<string[]>(() =>
    getClasses(firstVisit, breakpoint)
  );

  useEffect(() => {
    setClasses(getClasses(firstVisit, breakpoint));
  }, [firstVisit, breakpoint]);

  return (
    <div className={classes.join(' ')}>
      <div className={styles.header}>
        {props.animation && (
          <div className={styles.animation}>{props.animation}</div>
        )}
        <h1 className={styles.title}>{props.title}</h1>
        {props.baseline && (
          <div className={styles.baseline}>{props.baseline}</div>
        )}
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default ContentPage;

import React, { useState } from 'react';
import seLogo from '../../assets/images/se-logo.svg';
import seSmallLogo from '../../assets/images/se-small-logo.svg';
import Burger from '../../components/Burger/Burger';
import useHeaderAnim from '../../hooks/animations/useHeaderAnim';
import Navigation from '../Navigation/Navigation';
import Panel from '../Panel/Panel';
import styles from './Header.module.scss';

interface HeaderProps {
  route: string;
  se?: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  const { animation, isLeaving } = useHeaderAnim(300, props.route);
  const [burger, setBurger] = useState<boolean>(false);

  function handleToggle() {
    setBurger(!burger);
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.menu}>
          <a
            className={styles.logo}
            href={props.se || 'https://www.se.com'}
            target="_blank"
            rel="noreferrer">
            <img className={styles.normal} src={seLogo} alt="SE" />
            <img className={styles.small} src={seSmallLogo} alt="SE" />
          </a>
          <div className={styles.navigation}>
            <Navigation />
          </div>
        </div>
        <div className={styles.brand}>
          <img
            src={animation.x1}
            srcSet={`${animation.x1} 1x, ${animation.x2} 2x`}
            className={isLeaving ? styles.out : styles.in}
            alt=""
          />
          <span className={styles.bold}>Design</span> & Ergonomics
        </div>
        <Burger
          className={styles.burger}
          close={burger}
          onToggle={handleToggle}
        />
      </header>
      <Panel
        open={burger}
        className={styles.panel}
        onNavigate={() => setBurger(false)}
      />
    </>
  );
};

export default Header;

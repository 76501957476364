import { useEffect, useState } from 'react';
import { Breakpoint } from '../../enums/Breakpoint.enum';
import useWindowSize, { WindowSize } from './useWindowSize';

function getBreakpoint(width: number): Breakpoint {
  if (width <= Breakpoint.XSmall) {
    return Breakpoint.XSmall;
  } else if (width > Breakpoint.XSmall && width <= Breakpoint.Small) {
    return Breakpoint.Small;
  } else if (width > Breakpoint.Small && width <= Breakpoint.Medium) {
    return Breakpoint.Medium;
  } else if (width > Breakpoint.Medium && width <= Breakpoint.Large) {
    return Breakpoint.Large;
  } else if (width > Breakpoint.Large && width <= Breakpoint.XLarge) {
    return Breakpoint.XLarge;
  }

  return Breakpoint.None;
}

function useBreakpoint(): Breakpoint {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(() =>
    getBreakpoint(window.innerWidth)
  );
  const size: WindowSize = useWindowSize();

  useEffect(() => {
    const check: Breakpoint = getBreakpoint(size.width);
    if (check !== breakpoint) {
      setBreakpoint(check);
    }
  }, [breakpoint, size.width]);

  return breakpoint;
}

export default useBreakpoint;

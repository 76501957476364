import React from 'react';
import instaLogo from '../../assets/images/insta.svg';
import blogLogo from '../../assets/images/blog.svg';
import footerLogo from '../../assets/images/footer-logo.svg';
import Ripple from '../../components/Ripple/Ripple';
import styles from './Footer.module.scss';

interface FooterProps {
  yammer?: string;
  instagram?: string;
  copyright?: string;
  lastUpdate?: string;
}

const Footer: React.FC<FooterProps> = (props: FooterProps): JSX.Element => {
  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <div className={styles.social}>
          {(props.yammer || props.instagram) && (
            <>
              {props.yammer && (
                <a href={props.yammer} target="_blank" rel="noreferrer">
                  <img src={blogLogo} alt="Blog" />
                  <Ripple />
                </a>
              )}
              {props.instagram && (
                <a href={props.instagram} target="_blank" rel="noreferrer">
                  <img src={instaLogo} alt="Instagram" />
                  <Ripple />
                </a>
              )}
            </>
          )}
        </div>
        <img src={footerLogo} alt="Impact Company" />
      </div>
      <div className={styles.row}>
        <span className={styles.mentions}>
          {props.lastUpdate && `Last update ${props.lastUpdate}`}
        </span>
        <span className={styles.mentions}>{props.copyright}</span>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import styles from './HomeHeader.module.scss';

const HomeHeader: React.FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.hero} />

        <div className={styles.content}>
          <span className={styles.baseline}>
            We are
            <br />
            Schneider Electric&apos;s
          </span>
          <h1>
            Design <span className={styles.longname}>& Ergonomics</span>{' '}
            <span className={styles.shortname}>Lab</span>
          </h1>
          <span className={styles.intro}>
            A cross-disciplinary team creating meaningful customer experiences
            for hardware and digital products.
          </span>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;

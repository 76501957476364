import React, { useEffect } from 'react';
import styles from './Image.module.scss';

interface ImageProps {
  src?: string;
  srcSet?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Image: React.FC<ImageProps> = (props: ImageProps): JSX.Element => {
  const [src, setSrc] = React.useState<string | undefined>(props.src);
  const [srcSet, setSrcSet] = React.useState<string | undefined>(props.srcSet);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [classes, setClasses] = React.useState<string[]>([
    styles.image,
    styles.hidden,
    ...(props.className ? [props.className] : []),
  ]);

  // update image src and srcSet when props change (to avoid state/props render mismatch)
  useEffect(() => {
    if (props.src !== src || props.srcSet !== srcSet) {
      setLoaded(false);
      setSrc(props.src);
      setSrcSet(props.srcSet);
    }
  }, [props.src, props.srcSet, src, srcSet]);

  useEffect(() => {
    const classes: string[] = [styles.image];
    if (props.className) {
      classes.push(props.className);
    }

    if (!loaded) {
      classes.push(styles.hidden);
    }

    setClasses(classes);
  }, [props.className, loaded]);
  return (
    <img
      className={classes.join(' ')}
      src={src}
      style={props.style}
      srcSet={srcSet}
      alt=""
      onLoad={() => setLoaded(true)}
    />
  );
};

export default Image;

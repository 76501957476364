import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const cacheStore: { [key: string]: boolean } = {};

function useFirstVisit(): boolean {
  const location = useLocation();
  const [firstVisit] = useState(!cacheStore[location.pathname]);

  useEffect(() => {
    return () => {
      if (!cacheStore[location.pathname]) {
        cacheStore[location.pathname] = true;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return firstVisit;
}

export default useFirstVisit;

import React from 'react';
import { Link } from 'react-router-dom';
import Ripple from '../Ripple/Ripple';
import styles from './Button.module.scss';

interface ButtonProps {
  className?: string;
  children?: React.ReactNode;
  to?: string;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  return props.to ? (
    <Link to={props.to}>
      <button className={`${styles.button} ${props.className}`}>
        {props.children}
        <Ripple />
      </button>
    </Link>
  ) : (
    <button className={`${styles.button} ${props.className}`}>
      {props.children}
      <Ripple />
    </button>
  );
};

export default Button;

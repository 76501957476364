import { useEffect, useState } from 'react';

function checkMaxScroll(tolerance = 0): boolean {
  const maxScroll: number =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;

  return maxScroll - Math.ceil(window.scrollY) <= tolerance;
}

// hook that returns true if the page is scrolled to the bottom
function useMaxPageScroll(tolerance = 0): boolean {
  const [maxPageScroll, setMaxPageScroll] = useState<boolean>(() =>
    checkMaxScroll()
  );

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll: boolean = checkMaxScroll(tolerance);
      if (maxScroll !== maxPageScroll) {
        setMaxPageScroll(maxScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [maxPageScroll, tolerance]);

  return maxPageScroll;
}

export default useMaxPageScroll;

import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import styles from './CtaButton.module.scss';

interface CtaButtonProps {
  className?: string;
  target: string;
  cta: string;
  fallback: string;
}

const CtaButton: React.FC<CtaButtonProps> = (
  props: CtaButtonProps
): JSX.Element => {
  const [classes, setClasses] = useState([
    styles.wrapper,
    ...(props.className ? [props.className] : []),
  ]);

  useEffect(() => {
    setClasses([styles.wrapper, ...(props.className ? [props.className] : [])]);
  }, [props.className]);

  return (
    <div className={classes.join(' ')}>
      <Button className={styles.button} to={props.target}>
        {props.cta}
      </Button>
      <span className={styles.fallback}>{props.fallback}</span>
    </div>
  );
};

export default CtaButton;

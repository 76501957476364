import React, { useEffect, useState } from 'react';
import styles from './Ripple.module.scss';

const useRippleCleanUp = (
  rippleCount: number,
  duration: number,
  cleanUpFunction: () => void
) => {
  useEffect(() => {
    let bounce: NodeJS.Timeout | undefined;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 2);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

interface RippleProps {
  duration?: number;
  color?: string;
}

const Ripple: React.FC<RippleProps> = (props: RippleProps): JSX.Element => {
  const { duration, color } = props;
  const [rippleArray, setRippleArray] = useState<
    { x: number; y: number; size: number }[]
  >([]);

  useRippleCleanUp(rippleArray.length, duration as number, () => {
    setRippleArray([]);
  });

  const addRipple = (event: React.MouseEvent) => {
    const rippleContainer = (
      event.currentTarget as HTMLElement
    )?.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.clientX - rippleContainer.left - size / 2;
    const y = event.clientY - rippleContainer.top - size / 2;
    const newRipple = {
      x,
      y,
      size,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <div className={styles.ripple} onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              key={'span' + index}
              style={{
                top: ripple.y + 'px',
                left: ripple.x + 'px',
                width: ripple.size + 'px',
                height: ripple.size + 'px',
                backgroundColor: color,
                animationDuration: duration + 'ms',
              }}
            />
          );
        })}
    </div>
  );
};

Ripple.defaultProps = { duration: 600, color: '#fff' };

export default Ripple;

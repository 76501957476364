import React from 'react';
import Button from '../../components/Button/Button';
import { Urls } from '../../constants/Urls.constants';
import ContentPage from '../../layout/ContentPage/ContentPage';
import styles from './NotFound.module.scss';

const NotFound: React.FC = (): JSX.Element => {
  return (
    <ContentPage title="Not found">
      <div className={styles.wrapper}>
        <span>Sorry, the requested page was not found.</span>
        <Button to={Urls.home}>Go to homepage</Button>
      </div>
    </ContentPage>
  );
};

export default NotFound;

import React from 'react';
import { NavLink } from 'react-router-dom';
import Ripple from '../../components/Ripple/Ripple';
import { Urls } from '../../constants/Urls.constants';
import styles from './Panel.module.scss';

interface PanelProps {
  open?: boolean;
  className?: string;
  onNavigate?: () => void;
}

const Panel: React.FC<PanelProps> = (props: PanelProps): JSX.Element => {
  return (
    <ul
      className={`${styles.panel} ${props.open && styles.open} ${
        props.className
      }`}>
      <li>
        <NavLink
          className={styles.link}
          to={Urls.home}
          onClick={() => props.onNavigate && props.onNavigate()}>
          Home
          <Ripple />
        </NavLink>
      </li>
      <li>
        <NavLink
          className={styles.link}
          to={Urls.contact}
          onClick={() => props.onNavigate && props.onNavigate()}>
          Contact
          <Ripple />
        </NavLink>
      </li>
    </ul>
  );
};

export default Panel;

import { useEffect, useState } from 'react';
import { RequestStatus } from '../enums/RequestStatus.enum';

const cacheStore: { [key: string]: unknown } = {};

function useFetch<T>(
  url: string,
  cache = true
): [T | undefined, RequestStatus] {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle);
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    setStatus(RequestStatus.Fetching);

    // Checking if we should use cache
    if (cache && cacheStore[url]) {
      const cached: T = cacheStore[url] as T;
      setData(cached);
      setStatus(RequestStatus.Complete);
    } else {
      fetch(url)
        .then((response: Response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          return response.json();
        })
        .then((json: T) => {
          if (cache) {
            cacheStore[url] = json;
          }
          setData(json);
          setStatus(RequestStatus.Complete);
        })
        .catch(error => {
          console.warn(url);
          console.error(error);
          setStatus(RequestStatus.Error);
        });
    }
  }, [url, cache]);

  return [data, status];
}

export default useFetch;

import { RefObject, useEffect, useState } from 'react';
import useScrollRatio from './useScrollRatio';

const useElementVisible = (
  ref: RefObject<HTMLElement>,
  scrollThreshold?: number
): boolean => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollRatio = useScrollRatio(ref);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.5 }
    );

    const observed: HTMLElement | null = ref.current;
    if (observed) {
      observer.observe(observed);
    }

    return () => {
      if (observed) {
        observer.unobserve(observed);
      }
    };
  }, [ref]);

  return (
    isVisible &&
    (scrollThreshold === undefined || scrollRatio >= scrollThreshold)
  );
};

export default useElementVisible;

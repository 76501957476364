import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Ripple from '../../components/Ripple/Ripple';
import { Urls } from '../../constants/Urls.constants';
import styles from './Navigation.module.scss';

const Navigation: React.FC = (): JSX.Element => {
  const [clicked, setClicked] = React.useState<string | undefined>();
  const pathname = useLocation().pathname;

  useEffect(() => {
    setClicked(undefined);
  }, [pathname]);

  // Temporary class bypass to avoid blink during transition
  const handleClick = (url: string) => {
    setClicked(url);
  };

  const linkClassName = (url: string, isActive: boolean): string =>
    (isActive && !clicked) || clicked === url ? styles.active : '';

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <NavLink
            to={Urls.home}
            className={({ isActive }) => linkClassName(Urls.home, isActive)}
            onClick={() => handleClick(Urls.home)}>
            Home
            <Ripple />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={Urls.process}
            className={({ isActive }) => linkClassName(Urls.process, isActive)}
            onClick={() => handleClick(Urls.process)}>
            Process
            <Ripple />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={Urls.projects}
            className={({ isActive }) => linkClassName(Urls.projects, isActive)}
            onClick={() => handleClick(Urls.projects)}>
            Projects
            <Ripple />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={Urls.guidelines}
            className={({ isActive }) =>
              linkClassName(Urls.guidelines, isActive)
            }
            onClick={() => handleClick(Urls.guidelines)}>
            Guidelines
            <Ripple />
          </NavLink>
        </li>
        <li>
          <NavLink
            to={Urls.contact}
            className={({ isActive }) => linkClassName(Urls.contact, isActive)}
            onClick={() => handleClick(Urls.contact)}>
            Contact
            <Ripple />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;

import React, { ReactElement, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import { Segments } from './constants/Urls.constants';
import useViewTransition from './hooks/animations/useViewTransition';
import useFetch from './hooks/useFetch';
import Root from './layout/Root';
import { LayoutData } from './models/LayoutData';
import { StateData } from './models/StateData';
import StateContext from './context/StateContext';
import Home from './views/Home/Home';
import NotFound from './views/NotFound/NotFound';

const Projects = React.lazy(async () => import('./views/Projects/Projects'));
const Process = React.lazy(async () => import('./views/Process/Process'));
const Guidelines = React.lazy(
  async () => import('./views/Guidelines/Guidelines')
);
const Contact = React.lazy(async () => import('./views/Contact/Contact'));

function App(): ReactElement {
  const [state, setState] = React.useState<StateData>({});
  const route = useLocation().pathname;
  const { display, direction, isLeaving } = useViewTransition(350);

  const [layout] = useFetch<LayoutData>(
    process.env.PUBLIC_URL + '/data/layout.json'
  );

  useEffect(() => {
    setState((state: StateData) => ({ ...state, layout }));
  }, [layout]);

  const classes: string[] = ['wrapper'];
  classes.push(isLeaving ? 'out' : 'in');
  if (direction) {
    classes.push(direction > 0 ? 'right' : 'left');
  }

  return (
    <StateContext.Provider value={state}>
      <div className={classes.join(' ')}>
        <Routes location={display}>
          <Route path="/" element={<Root route={route} />}>
            <Route index element={<Home />} />
            <Route
              path={Segments.projects + '/*'}
              element={
                <React.Suspense fallback={<>...</>}>
                  <Projects />
                </React.Suspense>
              }
            />
            <Route
              path={Segments.process}
              element={
                <React.Suspense fallback={<>...</>}>
                  <Process />
                </React.Suspense>
              }
            />
            <Route
              path={Segments.guidelines}
              element={
                <React.Suspense fallback={<>...</>}>
                  <Guidelines />
                </React.Suspense>
              }
            />
            <Route
              path={Segments.contact}
              element={
                <React.Suspense fallback={<>...</>}>
                  <Contact />
                </React.Suspense>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </StateContext.Provider>
  );
}

export default App;
